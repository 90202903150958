import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  CashClosingDocument,
  CashClosingRows,
  CashClosingSummary,
} from '../../models';

export const featureAdapter: EntityAdapter<CashClosingDocument> =
  createEntityAdapter<CashClosingDocument>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<CashClosingDocument> {
  rows: CashClosingRows;
  summary: CashClosingSummary;
  isLoading?: boolean;
  error?: any;
  exportId: number;
}

export const initialState: State = featureAdapter.getInitialState({
  rows: null,
  summary: null,
  isLoading: false,
  error: null,
  exportId: null,
});
