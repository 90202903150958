import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CashClosingService } from '../../services';

import * as featureActions from './actions';
import { AttachmentViewerService } from '../../components/attachment-viewer/attachment-viewer.service';
import { TranslateService } from '@ngx-translate/core';
import { dateToServerFormat } from '../../helpers';
import { format } from 'date-fns';

@Injectable()
export class CashClosingStoreEffects {
  constructor(
    private dataService: CashClosingService,
    private actions$: Actions,
    private translate: TranslateService,
    private errorHandler: ErrorHandlerService,
    private attachmentViewer: AttachmentViewerService,
  ) {}

  loadRequestEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadRequest),
      switchMap(({ params }) =>
        this.dataService.load(params).pipe(
          map(({ data }) => {
            return featureActions.loadSuccess({
              data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(featureActions.loadFailure({ error }));
          }),
        ),
      ),
    ),
  );

  exportRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.exportRequest),
      switchMap(({ params: { dataToSend } }) =>
        this.dataService.export(dataToSend).pipe(
          map((response: any) => {
            if (+dataToSend.export) {
              return featureActions.exportSuccess({
                exportId: response.data.export_ids[0],
              });
            }
            this.attachmentViewer.view(
              new File(
                [response],
                `${this.translate.instant('closing_cash_desk_file')}_${format(
                  new Date(),
                  'yyyy-mm-dd',
                )}`,
                {
                  type: response.type,
                },
              ),
            );

            return featureActions.exportSuccess({ exportId: null });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(featureActions.exportFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
