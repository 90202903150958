import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CashClosingRows, CashClosingSummary } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getRows = (state: State): CashClosingRows => state.rows;

export const getSummary = (state: State): CashClosingSummary => state.summary;

export const getExportId = (state: State) => state.exportId;

export const selectState = createFeatureSelector<State>('cashClosing');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectCashClosingState =
  createFeatureSelector<State>('cashClosing');

export const selectCashClosingError = createSelector(
  selectCashClosingState,
  getError,
);

export const selectCashClosingIsLoading = createSelector(
  selectCashClosingState,
  getIsLoading,
);

export const selectCashClosingRows = createSelector(
  selectCashClosingState,
  getRows,
);

export const selectCashClosingSummary = createSelector(
  selectCashClosingState,
  getSummary,
);

export const selectExportId = createSelector(
  selectCashClosingState,
  getExportId,
);
