import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { data }) => {
    const { rows, summary } = data;

    return {
      ...state,
      rows,
      summary,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.exportSuccess, (state, { exportId }) => {
    return {
      ...state,
      exportId,
    };
  }),
  on(fromActions.resetState, () => fromState.initialState),
);

export function CashClosingReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
