import { createAction, props } from '@ngrx/store';
import {
  CashClosingExportRequest,
  CashClosingResponse,
  Invoice,
} from '../../models';

import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Cash Closing] Load Request',
  props<{ params: SearchParams }>(),
);

export const loadSuccess = createAction(
  '[Cash Closing] Load Success',
  props<{ data: CashClosingResponse }>(),
);

export const loadFailure = createAction(
  '[Cash Closing] Load Failure',
  props<{ error: string }>(),
);

export const exportRequest = createAction(
  '[Cash Closing] Export Request',
  props<{
    params: {
      dataToSend: CashClosingExportRequest;
    };
  }>(),
);

export const exportSuccess = createAction(
  '[Cash Closing] Export Success',
  props<{ exportId: number }>(),
);

export const exportFailure = createAction(
  '[Cash Closing] Export Failure',
  props<{ error: string }>(),
);

export const resetState = createAction('[Cash Closing] Reset State');
